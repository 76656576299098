<template>
    <div class="login-container">
      <h2>Welcome to the dungeon...</h2>
      <p>Please log in to view the card gallery:</p>
      <input
        v-model="username"
        type="text"
        placeholder="Enter your username"
        @keyup.enter="handleLogin"
      />
      <button @click="handleLogin">Login</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: "",
      };
    },
    methods: {
      handleLogin() {
        if (this.username.trim()) {
          this.$emit("login", this.username);
        } else {
          alert("Username cannot be empty.");
        }
      },
    },
  };
  </script>
  
  <style>
  .login-container {
    text-align: center;
    margin-top: 50px;
  }
  input {
    padding: 8px;
    margin: 5px;
  }
  button {
    padding: 10px 15px;
    cursor: pointer;
  }
  </style>
  