<template>
  <div id="app" class="hero">
    <div v-if="!isLoggedIn" class="overlay">
      <h1 class="headline">Welcome to MTG LOGEN!!!</h1>
      <p class="subheadline">Log in to explore your favorite cards!</p>
      <LoginPrompt @login="handleLogin" />
    </div>
    <div v-else>
      <CardGallery :username="username" />
    </div>
  </div>
</template>

<script>
import CardGallery from "./components/CardGallery.vue";
import LoginPrompt from "./components/LoginPrompt.vue";

export default {
  components: {
    LoginPrompt,
    CardGallery,
  },
  data() {
    return {
      isLoggedIn: false,
      username: "",
    };
  },
  methods: {
    handleLogin(username) {
      this.username = username;
      this.isLoggedIn = true;
    },
  },
};
</script>

<style>
/* Full-page background container */
.hero {
  height: 100vh; /* Full viewport height */
  width: 100%;
  background: url("/background.jpg") no-repeat center center/cover; /* Local image */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Transparent overlay for login */
.overlay {
  background: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

/* Headline styling */
.headline {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}

/* Subheadline styling */
.subheadline {
  font-size: 1.5rem;
  margin: 10px 0 20px;
}
</style>
