<template>
    <div class="gallery-container">
      <h2>Welcome, {{ username }}</h2>
      <p>Here are some Magic: The Gathering cards for you!</p>
      <div class="card-grid">
        <div v-for="card in cards" :key="card.id" class="card">
          <img :src="card.image" :alt="card.name" />
          <p>{{ card.name }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    props: ["username"],
    data() {
      return {
        cards: [],
      };
    },
    async mounted() {
      try {
        const response = await axios.get(
          "https://api.scryfall.com/cards/search?q=game:paper"
        );
        this.cards = response.data.data.slice(0, 10).map((card) => ({
          id: card.id,
          name: card.name,
          image: card.image_uris?.normal || card.image_uris?.small || "",
        }));
      } catch (error) {
        console.error("Error fetching MTG cards:", error);
      }
    },
  };
  </script>
  
  <style>
  .gallery-container {
    text-align: center;
  }
  .card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    padding: 20px;
  }
  .card {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .card img {
    width: 100%;
    height: auto;
  }
  </style>
  